import API from "shared/constants/api";
import { default as DATADOG_DEFAULTS } from "shared/constants/datadog";
import getEnvironment from "shared/utils/getEnvironment";

export const CONFIG = {
  ...DATADOG_DEFAULTS.CONFIG,
  ALLOWED_TRACING_URLS: [
    {
      match: API.INTERNAL[getEnvironment()].replace("/query", ""),
      propagatorTypes: ["datadog" as const],
    },
  ],
  APPLICATION_ID: "5ed38ee9-854d-4ab7-ad73-08ee2f1b2138",
  CLIENT_TOKEN: "pub9227ad8fbf243fd1b4b0b6877b5aace0",
  SERVICE: "showreel.vidsy.co",
};

export const IGNORE_ERRORS = [...DATADOG_DEFAULTS.IGNORE_ERRORS];

export default {
  CONFIG,
  IGNORE_ERRORS,
};
