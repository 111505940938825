import { ReactNode, useReducer, createContext } from "react";
import WIZARD from "shared/constants/actions/wizard";
import ANALYTICS from "shared/constants/analytics";
import { IAction } from "shared/types/actions";
import { IConfigItem } from "shared/types/wizard";
import fireAnalyticsEvent from "shared/utils/fireAnalyticsEvent";

export const INITIAL_STATE = {
  config: [],
  currentItemIndex: 0,
  id: "",
};

interface IProps {
  children: ReactNode;
}

interface IWizardState {
  config: (IConfigItem | null)[];
  currentItemIndex: number;
  id: string;
}

interface IContext {
  dispatch: React.Dispatch<IAction>;
  wizardDetails: IWizardState;
}

export const WizardContext = createContext<IContext>({
  dispatch: () => null,
  wizardDetails: INITIAL_STATE,
});

export const wizardReducer = (state: IWizardState, action: IAction) => {
  switch (action.type) {
    case WIZARD.ADD: {
      fireAnalyticsEvent(
        ANALYTICS.CATEGORIES.WIZARD,
        ANALYTICS.EVENTS.WIZARD_OPENED,
        { id: action.payload.id },
      );
      return {
        ...INITIAL_STATE,
        config: action.payload.config,
        id: action.payload.id,
      };
    }
    case WIZARD.NEXT_ITEM: {
      if (state.currentItemIndex < state.config.length - 1) {
        return {
          ...state,
          currentItemIndex: state.currentItemIndex + 1,
        };
      }
      break;
    }
    case WIZARD.REMOVE: {
      fireAnalyticsEvent(
        ANALYTICS.CATEGORIES.WIZARD,
        ANALYTICS.EVENTS.WIZARD_CLOSED,
        {
          id: state.id,
          tipsViewed: state.currentItemIndex + 1,
          totalTips: state.config.length,
        },
      );
      return INITIAL_STATE;
    }
    default:
      break;
  }
  return state;
};

function WizardContextProvider({ children }: IProps) {
  const [wizardState, dispatch] = useReducer(wizardReducer, INITIAL_STATE);

  return (
    <WizardContext.Provider value={{ dispatch, wizardDetails: wizardState }}>
      {children}
    </WizardContext.Provider>
  );
}

export default WizardContextProvider;
