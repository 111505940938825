// Alignment.BottomBottom equates to
// aligning the bottom of the modal to the bottom of the spotlight
export enum Alignment {
  BottomBottom = "BOTTOM_BOTTOM",
  BottomMiddle = "BOTTOM_MIDDLE",
  BottomTop = "BOTTOM_TOP",
  TopBottom = "TOP_BOTTOM",
  TopMiddle = "TOP_MIDDLE",
  TopTop = "TOP_TOP",
}

// ArrowPosition.RightTop equates to
// position the arrow on the left side of the modal, near the top
export enum ArrowPosition {
  Left = "LEFT",
  LeftBottom = "LEFT_BOTTOM",
  LeftTop = "LEFT_TOP",
  Right = "RIGHT",
  RightBottom = "RIGHT_BOTTOM",
  RightTop = "RIGHT_TOP",
}

// Position.Left equates to
// position the modal to the left of the spotlight
export enum Position {
  Left = "LEFT",
  Right = "RIGHT",
}

export interface IAlignment {
  dynamic?: boolean;
  type: Alignment;
}

interface IModal {
  alignment: IAlignment;
  arrowPosition: ArrowPosition;
  content: string;
  position: Position;
}

export interface IConfigItem {
  id: string;
  modal: IModal;
  partialMatch?: boolean;
}

export default { Alignment, ArrowPosition, Position };
