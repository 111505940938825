import { HTMLAttributes } from "react";
import Button, { secondaryButtonStyles } from "shared/components/Button";
import COLORS from "shared/constants/colors";
import FONTS from "shared/constants/fonts";
import { ArrowPosition as Position } from "shared/types/wizard";
import styled, { css } from "styled-components";

interface IContainer extends HTMLAttributes<HTMLDivElement> {
  position: Position;
}

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

export const Close = styled(Button)`
  ${secondaryButtonStyles}
`;

const arrowStyles = {
  [Position.Left]: css`
    border-right-color: ${COLORS.LIGHT_GREY};
    border-width: 0.5rem 0.75rem 0.5rem 0;
    left: -0.75rem;
    top: 50%;
    transform: translateY(-0.5rem);
  `,
  [Position.LeftBottom]: css`
    border-right-color: ${COLORS.LIGHT_GREY};
    border-width: 0.5rem 0.75rem 0.5rem 0;
    left: -0.75rem;
    bottom: 1rem;
    transform: translateY(0.5rem);
  `,
  [Position.LeftTop]: css`
    border-right-color: ${COLORS.LIGHT_GREY};
    border-width: 0.5rem 0.75rem 0.5rem 0;
    left: -0.75rem;
    top: 2rem;
    transform: translateY(-0.5rem);
  `,
  [Position.Right]: css`
    border-left-color: ${COLORS.LIGHT_GREY};
    border-width: 0.5rem 0 0.5rem 0.75rem;
    right: -0.75rem;
    top: 50%;
    transform: translateY(-0.5rem);
  `,
  [Position.RightBottom]: css`
    border-left-color: ${COLORS.LIGHT_GREY};
    border-width: 0.5rem 0 0.5rem 0.75rem;
    right: -0.75rem;
    bottom: 1rem;
    transform: translateY(0.5rem);
  `,
  [Position.RightTop]: css`
    border-left-color: ${COLORS.LIGHT_GREY};
    border-width: 0.5rem 0 0.5rem 0.75rem;
    right: -0.75rem;
    top: 2rem;
    transform: translateY(-0.5rem);
  `,
};

export const Container = styled.div<IContainer>`
  background-color: ${COLORS.LIGHT_GREY};
  border-radius: 0.25rem;
  display: flex;
  flex: 1 auto 1;
  flex-direction: column;
  padding: 1rem;

  &::before {
    border-color: transparent;
    border-style: solid;
    content: "";
    height: 0;
    opacity: 1;
    position: absolute;
    width: 0;
    ${(props) => arrowStyles[props.position]};
  }
`;

export const Content = styled.div`
  max-width: 18.625rem;
  overflow-x: hidden;
  overflow-y: auto;
  word-break: break-word;

  h3 {
    font-size: 0.875rem;
  }

  h3,
  p:not(:last-child) {
    padding-bottom: 1rem;
  }

  video {
    max-width: 18.625rem;
  }
`;

export const Heading = styled.h2`
  font-size: 0.625rem;
  font-weight: ${FONTS.WEIGHT.BOLD};
  letter-spacing: 0.85px;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
`;
