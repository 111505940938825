import Modal from "shared/components/Wizard/Modal";
import COLORS from "shared/constants/colors";
import { Alignment, IAlignment, Position } from "shared/types/wizard";
import styled, { css } from "styled-components";

export const MODAL_MARGIN = 8;
export const SPOTLIGHT_PADDING = {
  HORIZONTAL: 8,
  VERTICAL: 16,
};
export const MODAL_WIDTH = 330;

const alignmentStyles = (spotlightRect: DOMRect | null) => ({
  [Alignment.BottomBottom]: () => {
    const maxHeight = spotlightRect ? spotlightRect.bottom : 0;
    return css`
      max-height: ${Math.round(maxHeight - MODAL_MARGIN)}px;
      bottom: calc(100vh - ${maxHeight}px);
    `;
  },
  [Alignment.BottomMiddle]: () => {
    const maxHeight = spotlightRect
      ? Math.round(
          spotlightRect.bottom - spotlightRect.height / 2 + MODAL_MARGIN,
        )
      : 0;
    return css`
      max-height: ${Math.round(maxHeight - MODAL_MARGIN)}px;
      bottom: calc(100vh - ${maxHeight}px);
    `;
  },
  [Alignment.BottomTop]: () => {
    const maxHeight = spotlightRect
      ? Math.round(spotlightRect.bottom - spotlightRect.height + MODAL_MARGIN)
      : 0;
    return css`
      max-height: ${Math.round(maxHeight - MODAL_MARGIN)}px;
      bottom: calc(100vh - ${maxHeight}px);
    `;
  },
  [Alignment.TopBottom]: () => {
    const top = spotlightRect
      ? Math.round(
          spotlightRect.top + spotlightRect.height - SPOTLIGHT_PADDING.VERTICAL,
        )
      : 0;
    return css`
      max-height: calc(100vh - ${top}px - ${MODAL_MARGIN}px);
      top: ${top}px;
    `;
  },
  [Alignment.TopMiddle]: () => {
    const top = spotlightRect
      ? Math.round(spotlightRect.top + spotlightRect.height / 2)
      : 0;
    return css`
      max-height: calc(100vh - ${top}px - ${MODAL_MARGIN}px);
      top: ${top}px;
    `;
  },
  [Alignment.TopTop]: () => {
    const top = spotlightRect
      ? Math.round(spotlightRect.top - SPOTLIGHT_PADDING.VERTICAL)
      : 0;
    return css`
      max-height: calc(100vh - ${top}px - ${MODAL_MARGIN}px);
      top: ${top}px;
    `;
  },
});

const calculateAlignment = (spotlightRect: DOMRect) => {
  const alignment =
    window.innerHeight / 2 > spotlightRect.top
      ? Alignment.TopTop
      : Alignment.BottomTop;

  return alignmentStyles(spotlightRect)[alignment];
};

const overrideArrowPosition = (spotlightRect: DOMRect) => {
  return css`
    &::before {
      ${window.innerHeight / 2 > spotlightRect.top
        ? "top: 2rem; bottom: unset; transform: translateY(-0.5rem);"
        : "top: unset; bottom: 1rem; transform: translateY(0.5rem);"}
    }
  `;
};

const positionStyles = (spotlightRect: DOMRect | null) => ({
  [Position.Left]: css`
    left: ${spotlightRect
      ? `${Math.round(
          spotlightRect.left -
            MODAL_WIDTH -
            SPOTLIGHT_PADDING.HORIZONTAL * 2 -
            MODAL_MARGIN,
        )}px`
      : 0};
  `,
  [Position.Right]: css`
    left: ${spotlightRect
      ? `${Math.round(
          spotlightRect.left +
            spotlightRect.width +
            SPOTLIGHT_PADDING.HORIZONTAL * 2 +
            MODAL_MARGIN,
        )}px`
      : 0};
  `,
});

export const StyledModal = styled(Modal)<{
  alignment: IAlignment;
  position: Position;
  spotlightRect: DOMRect | null;
}>`
  position: absolute;
  z-index: 9999;
  ${({ position, spotlightRect }) => positionStyles(spotlightRect)[position]}
  ${({ alignment, spotlightRect }) =>
    alignment.dynamic && spotlightRect
      ? calculateAlignment(spotlightRect)
      : alignmentStyles(spotlightRect)[alignment.type]}
  ${({ alignment, spotlightRect }) =>
    alignment.dynamic && spotlightRect && overrideArrowPosition(spotlightRect)}
`;

export const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.75);
  bottom: 0;
  height: 100vh;
  left: 0;
  mix-blend-mode: hard-light;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
`;

export const Spotlight = styled.div<{ spotlightRect: DOMRect | null }>`
  background: ${COLORS.GREY};
  border-radius: 0.25rem;
  height: ${({ spotlightRect }) =>
    spotlightRect
      ? `${Math.round(spotlightRect.height + SPOTLIGHT_PADDING.VERTICAL * 2)}px`
      : 0};
  left: ${({ spotlightRect }) =>
    spotlightRect
      ? `${Math.round(spotlightRect.left - SPOTLIGHT_PADDING.HORIZONTAL)}px`
      : 0};
  opacity: 1;
  position: absolute;
  top: ${({ spotlightRect }) =>
    spotlightRect
      ? `${Math.round(spotlightRect.top - SPOTLIGHT_PADDING.VERTICAL)}px`
      : 0};
  width: ${({ spotlightRect }) =>
    spotlightRect
      ? `${Math.round(
          spotlightRect.width + SPOTLIGHT_PADDING.HORIZONTAL * 2,
        )}px`
      : 0};
  z-index: 9999;
`;
