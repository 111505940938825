import { InMemoryCache } from "@apollo/client";

const cache = new InMemoryCache({
  typePolicies: {
    Showreel: {
      keyFields: ["guid"],
    },
    ShowreelFeedback: {
      fields: {
        feedback: {
          merge(existing, incoming = []) {
            // Always use the latest update, to allow deleting comments
            return [...incoming];
          },
        },
        selectedVideos: {
          merge(existing, incoming = []) {
            // Always use the latest update, to allow removing videos from selection
            return [...incoming];
          },
        },
      },
      keyFields: ["showreelID"],
    },
    VariantFeedback: {
      keyFields: ["hash", "videoID"],
      fields: {
        comments: {
          merge(existing, incoming) {
            // Always use the latest update, to allow deleting comments
            // A "no feedback" scenario can lead to `incoming` equal to `null`
            return [...(incoming || [])];
          },
        },
      },
    },
    Video: {
      fields: {
        notes: {
          merge(existing, incoming) {
            return { ...existing, ...incoming };
          },
        },
      },
    },
  },
});

export default cache;
