import WIZARD from "shared/constants/actions/wizard";
import { IConfigItem } from "shared/types/wizard";

interface IPayload {
  config?: (IConfigItem | null)[];
  currentIndex?: number;
  id?: string;
}

export const addWizardAction = (payload: IPayload) => ({
  payload,
  type: WIZARD.ADD,
});

export const nextItemAction = () => ({
  type: WIZARD.NEXT_ITEM,
});

export const removeWizardAction = () => ({
  type: WIZARD.REMOVE,
});
