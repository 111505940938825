import { ApolloProvider } from "@apollo/client";
import { datadogRum } from "@datadog/browser-rum";
import { Suspense } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Analytics from "shared/components/Analytics";
import SqueezedError from "shared/components/errors/SqueezedError";
import UnrecoverableError from "shared/components/errors/UnrecoverableError";
import FeatureProvider from "shared/components/FeatureProvider";
import GlobalStyle from "shared/components/GlobalStyle";
import LDIdentifier from "shared/components/LDIdentifier";
import LoadingElement from "shared/components/LoadingElement";
import ModalProvider from "shared/components/ModalProvider";
import ToastContainer from "shared/components/ToastContainer";
import ToastProvider from "shared/components/ToastProvider";
import WizardController from "shared/components/Wizard/Controller";
import WizardProvider from "shared/components/Wizard/Provider";
import ANALYTICS from "shared/constants/analytics/config";
import NotFound from "shared/pages/NotFound";
import Environment from "shared/types/environment";
import { IRouteProps } from "shared/types/routes";
import getDatadogBeforeSend from "shared/utils/getDatadogBeforeSend";
import getEnvironment from "shared/utils/getEnvironment";
import textualize from "shared/utils/textualize";
import CrashReporterBoundary from "showreel/components/CrashReporterBoundary";
import DATADOG from "showreel/constants/datadog";
import routes, { GUID_REGEX } from "showreel/routes";
import client from "showreel/services/GraphQL/client";
import ldClient from "showreel/services/LaunchDarkly/client";
import Session from "showreel/services/Session";

function App() {
  const environment = getEnvironment();
  const segmentID = ANALYTICS[environment];

  if (environment !== Environment.Development) {
    datadogRum.init({
      allowedTracingUrls: DATADOG.CONFIG.ALLOWED_TRACING_URLS,
      applicationId: DATADOG.CONFIG.APPLICATION_ID,
      beforeSend: getDatadogBeforeSend(client, DATADOG),
      clientToken: DATADOG.CONFIG.CLIENT_TOKEN,
      defaultPrivacyLevel: DATADOG.CONFIG.DEFAULT_PRIVACY_LEVEL,
      env: environment,
      service: DATADOG.CONFIG.SERVICE,
      sessionSampleRate: DATADOG.CONFIG.SESSION_SAMPLE_RATE,
      sessionReplaySampleRate: DATADOG.CONFIG.SESSION_REPLAY_SAMPLE_RATE,
      site: DATADOG.CONFIG.SITE,
      trackUserInteractions: DATADOG.CONFIG.TRACK_USER_INTERACTIONS,
      version: `${process.env.VERSION}`,
    });
  }

  return (
    <BrowserRouter>
      <SqueezedError>
        <div>
          <CrashReporterBoundary fallback={UnrecoverableError}>
            <ApolloProvider client={client}>
              <Helmet
                defaultTitle={textualize("general.appTitle") as string}
                titleTemplate={`%s - ${textualize("general.appTitle")}`}
              />
              <FeatureProvider
                client={ldClient}
                loadingComponent={<LoadingElement />}
              >
                <CrashReporterBoundary fallback={UnrecoverableError}>
                  <ToastProvider>
                    <ToastContainer />
                    <ModalProvider>
                      <WizardProvider>
                        <WizardController />
                        <Suspense fallback={<LoadingElement />}>
                          <Switch>
                            <Redirect
                              from={`/:showreelGUID(${GUID_REGEX})/videos/:shortHash/feedback`}
                              to={`/:showreelGUID(${GUID_REGEX})/videos/:shortHash/review`}
                            />
                            {routes.map((route: IRouteProps, i: number) => {
                              const {
                                authRequired,
                                component: Component,
                                ...rest
                              } = route;

                              return (
                                <Route key={i} {...rest}>
                                  <Component />
                                </Route>
                              );
                            })}
                            <Route key={routes.length + 1}>
                              <NotFound />
                            </Route>
                          </Switch>
                        </Suspense>
                      </WizardProvider>
                    </ModalProvider>
                  </ToastProvider>
                  {environment !== Environment.Development && (
                    <Analytics
                      isAuthenticated={Session.isAuthenticated}
                      segmentID={segmentID}
                    />
                  )}
                  <LDIdentifier client={ldClient} />
                </CrashReporterBoundary>
              </FeatureProvider>
            </ApolloProvider>
          </CrashReporterBoundary>
          <GlobalStyle />
        </div>
      </SqueezedError>
    </BrowserRouter>
  );
}

export default App;
