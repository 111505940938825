import { WheelEvent } from "react";

export enum ScrollDirection {
  x = "X",
  y = "Y",
  z = "Z",
}

const blockScrollEvents =
  (prohibitedEvents: ScrollDirection[]) => (e: WheelEvent) => {
    if (prohibitedEvents.includes(ScrollDirection.x) && e.deltaX) {
      e.nativeEvent.stopImmediatePropagation();
    }

    if (prohibitedEvents.includes(ScrollDirection.y) && e.deltaY) {
      e.nativeEvent.stopImmediatePropagation();
    }

    if (prohibitedEvents.includes(ScrollDirection.z) && e.deltaZ) {
      e.nativeEvent.stopImmediatePropagation();
    }
  };

export default blockScrollEvents;
