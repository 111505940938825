import { useCallback, useContext } from "react";
import ReactDom from "react-dom";
import { nextItemAction, removeWizardAction } from "shared/actions/wizard";
import { WizardContext } from "shared/components/Wizard/Provider";
import KEYBOARD_SHORTCUTS from "shared/constants/shortcuts/keyboardShortcuts";
import { IConfigItem } from "shared/types/wizard";
import blockKeyboardShortcuts from "shared/utils/blockKeyboardShortcuts";
import blockScrollEvents, {
  ScrollDirection,
} from "shared/utils/blockScrollEvents";
import { Overlay, Spotlight, StyledModal } from "./styles";

function getElementInView(element: HTMLElement | Element): DOMRect {
  const boundingClientRect = element.getBoundingClientRect();
  const outOfView =
    window.innerHeight < boundingClientRect.bottom ||
    boundingClientRect.top < 0;

  return outOfView && !!element.parentElement
    ? getElementInView(element.parentElement)
    : boundingClientRect;
}

function getSpotlightRect(configItem: IConfigItem) {
  const spotlight = configItem?.partialMatch
    ? document.querySelector(`[id^="${configItem.id}-"]`)
    : document.getElementById(configItem.id);

  return spotlight ? getElementInView(spotlight) : null;
}

function WizardController() {
  const { dispatch, wizardDetails } = useContext(WizardContext);

  const currentItem =
    wizardDetails.config &&
    wizardDetails.config[wizardDetails.currentItemIndex];
  const spotlightRect = currentItem ? getSpotlightRect(currentItem) : null;

  const onClose = useCallback(() => dispatch(removeWizardAction()), [dispatch]);
  const onNext = useCallback(() => dispatch(nextItemAction()), [dispatch]);

  // Wizard keyboard shortcuts
  const shortcutHandler = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === KEYBOARD_SHORTCUTS.ESCAPE) {
        e.preventDefault();
        onClose();
      }
    },
    [onClose],
  );

  const node = (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onKeyDown={shortcutHandler}
      onKeyUp={blockKeyboardShortcuts(Object.values(KEYBOARD_SHORTCUTS))}
      onWheel={blockScrollEvents([ScrollDirection.y])}
      tabIndex={-1}
    >
      {spotlightRect && currentItem && (
        <>
          <Overlay>
            <Spotlight
              id={`${wizardDetails.id}Spotlight`}
              spotlightRect={spotlightRect}
            />
          </Overlay>

          <StyledModal
            {...currentItem.modal}
            id={`${wizardDetails.id}Modal`}
            index={wizardDetails.currentItemIndex + 1}
            onClose={onClose}
            onNext={onNext}
            spotlightRect={spotlightRect}
            total={wizardDetails.config.length}
          />
        </>
      )}
    </div>
  );

  return ReactDom.createPortal(
    node,
    document.getElementById("wizard-root") as Element,
  );
}

export default WizardController;
