import Environment from "shared/types/environment";
import processEnvWarning from "shared/utils/processEnvWarning";

processEnvWarning(["CLIENT_SIDE_ID"], "LD");

export const LAUNCH_DARKLY = {
  [Environment.Development]:
    process.env.LD_CLIENT_SIDE_ID || "62286f6705ab8b14c8d21d45",
  [Environment.Live]: "62286f883576ea1531e05df4",
  [Environment.Staging]:
    process.env.LD_CLIENT_SIDE_ID || "62286f7a05ab8b14c8d21d49",
};

export default LAUNCH_DARKLY;
