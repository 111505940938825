import { marked } from "marked";
import { HTMLAttributes, useEffect, useRef } from "react";
import Button from "shared/components/Button";
import { ArrowPosition as Position } from "shared/types/wizard";
import textualize from "shared/utils/textualize";
import { Actions, Close, Container, Content, Heading } from "./ModalComponents";

interface IProps extends HTMLAttributes<HTMLDivElement> {
  arrowPosition: Position;
  content: string;
  index: number;
  onClose: () => void;
  onNext: () => void;
  total: number;
}

function Modal({
  arrowPosition,
  className,
  content,
  index,
  id,
  onClose,
  onNext,
  total,
}: IProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const isFirstItem = index === 1;
  const isLastItem = index === total;

  useEffect(() => {
    if (isFirstItem || isLastItem) {
      buttonRef.current?.focus();
    }
  }, [buttonRef, isFirstItem, isLastItem]);

  return (
    <Container className={className} id={id} position={arrowPosition}>
      <Heading id={`${id}Heading`}>
        {textualize("wizard.modal.index", { index, total })}
      </Heading>
      <Content
        dangerouslySetInnerHTML={{
          __html: marked(content),
        }}
        id={`${id}Content`}
      />
      <Actions>
        <Close
          id={`${id}Close`}
          onClick={onClose}
          ref={isLastItem ? buttonRef : null}
        >
          {textualize("wizard.modal.close")}
        </Close>
        {!isLastItem && (
          <Button
            id={`${id}Next`}
            onClick={onNext}
            ref={isFirstItem ? buttonRef : null}
          >
            {textualize("wizard.modal.next")}
          </Button>
        )}
      </Actions>
    </Container>
  );
}

export default Modal;
