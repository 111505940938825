import { KeyboardEvent } from "react";

export const blockKeyboardShortcuts =
  (keys: string[]) => (e: KeyboardEvent) => {
    if (keys.includes(e.key)) {
      // Stop React events from going to `window`
      e.nativeEvent.stopImmediatePropagation();
    }
  };

export default blockKeyboardShortcuts;
